import React from "react";

const DialogSuccess = () => {
  return (
    <>
      <div className="signup-context-title">Password Changed</div>
      <div className="forgot-password-description">
        Your password has been changed successfully.
      </div>
    </>
  );
};

export default DialogSuccess;
